import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

const Terms = () => (
  <Layout>
    <Helmet title="Terms & conditions | Rudd Joinery">
      <meta name="description" content="" />
    </Helmet>
    <div className="section">
      <div className="wrapper">
        <div className="story-block story">
          <h1>Terms &amp; Conditions</h1>
          <p>
            Orders accepted subject to 30% deposit and balance due on completion of
            order.
            <br />
            All goods remain the property of Rudd Joinery Limited until paid in
            full.
            <br />
            Items remain recoverable unless payment is received in full.
          </p>

          <p>
            All quotations are based on information supplied and we would request
            you check the contents to ensure that we have interpreted your
            requirements accurately. Any revisions made since the receipt of
            relevant information may not have been included in quotations.
          </p>

          <h3>Terms Of Payment</h3>

          <p>
            Non account holders are required to pay quoted deposit prior to
            manufacture and balance due on completion of order.
          </p>

          <p>
            Account holders strictly 30 days from date of invoice. All accounts not
            settled within agreed time will automatically be on stop until payment
            received in full. Warning of this may not always be possible.
          </p>

          <p>
            Late payment charges; Interest will be charged at Bank of England base
            rate + 8%.
          </p>

          <p>
            Any monies outstanding beyond our credit terms will be passed into the
            hands of the County Court and will be subject to a surcharge to cover
            cost incurred in obtaining settlement.
          </p>

          <h3>Cancellation</h3>

          <p>
            The Company reserves the right to refuse cancellation of an order, and
            cancellation will only be accepted after a written approval from the
            Company.
          </p>

          <h3>Data Protection</h3>

          <p>
            Rudd Joinery Limited are a registered data controller who comply with
            the Data Protection Act 1998. Rudd Joinery Limited will make a search
            with a credit reference agency, which will keep a record of that search
            and will share the information with other businesses. Rudd Joinery
            Limited may also make enquiries about the principal directors with a
            credit reference agency.
          </p>

          <h3>Complaints</h3>

          <p>
            Any complaint by the client relating to invoices must be notified by the
            client to us in writing within 15 days from the date of the invoice.
          </p>

          <p class="em">
            It is a condition precedent to any liability of ours that:
          </p>

          <ol>
            <li>
              All goods shall be stored in a dry place, stacked flat, slightly
              raised from the ground and adequately protected from the weather and
              that no internal joinery shall be installed in a building which has
              not adequately dried out.
            </li>
            <li>
              All joinery supplied in the white shall be knotted and primed without
              delay after receipt and all joinery supplied shall receive further
              coats of paint within a reasonable period. Joinery supplied with a
              basecoat of stain shall receive further coats as soon as possible.
            </li>
            <li>
              Any surfaces subsequently cut, particularly that exposing end grain
              shall be brush coated with preservative and if required for paint
              finish shall also be primed before the joinery is fixed in position.
            </li>
            <li>
              All joinery shall be installed correctly in accordance with normal
              trade practices and adequately maintained in service.
            </li>
            <li>
              Paintwork or other protective surface finish of external joinery shall
              be maintained in service and moisture shall not be allowed to
              penetrate into the timber.
            </li>
            <li>
              Internal door frames and linings, also frames for garage doors, are
              prepared ready for assembly on site but supplied unassembled unless
              specified to the contrary.
            </li>
          </ol>

          <p>
            We reserve the right to use laminated or jointed timber in accordance
            with standard practice.
          </p>

          <p>
            We reserve the right to modify our specification should this prove
            necessary.
          </p>

          <p>
            Although the Company will make every effort to deliver on the agreed
            date, delivery time is not of the essence of the contract. Any quoted
            delivery dates are estimated only and the Company shall not be liable
            for any loss or damage whatever, caused by delayed delivery of goods.
            Delay in delivery will not entitle the Buyer to rescind the contract.
          </p>

          <p>
            Rudd Joinery Limited will not be held responsible for the operation in
            doors and locks for any minor adjustments to our pre hung door sets if
            fitted by a third party, this is the responsibility of the installer.
          </p>

          <p>
            Rudd Joinery Limited will not be held responsible for the adjustment of
            spring balances in sliding sash windows if the said windows are
            installed by a third party, this is the responsibility of the installer.
          </p>
        </div>
        </div>
    </div>
  </Layout>
)

export default Terms
